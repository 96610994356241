<template>
    <div class="catalog">
        <div class="catalog-options">
            <div class="option" @click="modle = 'add-applications'">
                <i class="material-icons">supervised_user_circle</i>
                <p>הוסף אפליקציות</p>
            </div>
            <div class="option" @click="modle='permissions'">
                <i class="material-icons">supervised_user_circle</i>
                <p>ניהול הרשאות</p>
            </div>
            <div class="option" @click="modle = 'use_data'">
                <i class="material-icons">supervised_user_circle</i>
                <p>נתוני שימוש</p>
            </div>
        </div>
        <div class="catalog-panel">
           <AddApplications v-if="modle =='add-applications'" />
        </div>  
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import AddApplications from '../../components/Admin/Applications/AddApplications.vue'
export default {
components:{AddApplications},
setup(){
    const modle = ref('')
    return{
        modle,
    }
}
}
</script>

<style scoped>
.catalog{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 350px calc(100% - 350px);
    overflow-y: auto;
}
.catalog-options{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: 50px;
    gap: 8px;
    overflow-y: auto;
}
.option{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 26px;
    background: var(--secondary);
    padding: 0 5px 0 0;
    border-radius: 5px;
    cursor: pointer;
}
.option:hover p{
    margin-right:25px;
    color: var(--yellow);
}
.option i {
    margin-left: 25px;
    color: var(--light);
    font-size: 33px;
}
.option p{
    color:var(--light);
    transition: 0.25s;
}
.catalog-panel{
    width: 100%;
    height: 100%;
    padding: 0 5px 0 5px;
}

@media only screen and (max-width: 660px) {
    .catalog{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 180px 600px;
        grid-template-columns: 100%;
    }
    .catalog-panel{
        height: 100%;
        padding: 0 0 0 0;
    }
    ::-webkit-scrollbar{
        width: 0;
    }
}
</style>