<template>
    <div class="add-applications">
        <div class="field">
            <h1>שם האפליקציה</h1>
            <el-input v-model="values.name" class="input" placeholder="שם האפליקציה"></el-input>
        </div>
        <div class="field">
            <h1>אייקון</h1>
            <el-input v-model="values.icon" class="input" placeholder="שם האייקון"></el-input>
        </div>
        <div class="field">
            <h1>נתיב לאפליקציה - (router)</h1>
            <el-input v-model="values.route" class="input" placeholder="נתיב"></el-input>
        </div>
        <div class="field">
            <h1>מינימום הרשאה</h1>
            <el-input-number v-model="values.permission" class="input" :min="25" :max="10000" :step="1" ></el-input-number>
        </div>
        <div class="field">
             <el-button @click="handle_create_application" class="input" type="success">צור אפליקציה</el-button>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {alert} from '../../../Methods/Msgs'
import {projectFirestore} from '../../../firebase/config'
export default {
    setup(){
        const error_msg=ref('')
        const values = ref({
            name:'',
            icon:'',
            route:'',
            permission:25,
            id:''
        })

        const handle_create_application=async()=>{
            if(!validation()){
                alert('error','שגיאה',error_msg.value)
            }else{
                const doc = projectFirestore.collection('Applications').doc()
                await doc.set({...values.value,id:doc.id},{merge:true})

                alert('success','הצלחה',`
                    האפלקיציה ${values.value.name} נוצרה בהצלחה
                `).then(()=>reset_values())

            }
        }
        const validation = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'name':
                        if(value.length==0){
                            error_msg.value='עליך למלא שם לאפליקציה'
                            return false
                        }
                        break;
                    case 'icon':
                        if(value.length==0){
                            error_msg.value='חובה לשים אייקון לאפליקציה'
                            return false
                        }
                        break;
                    case 'route':
                        if(value.length==0){
                            error_msg.value='חובה להזין נתיב לאפליקציה'
                            return false
                        }
                        break;


                    default:
                        break;
                }
            }
            return true
        }

        const reset_values = ()=>{
            values.value = {
                name:'',
                icon:'',
                route:'',
                permission:25,
                id:''
            }
        }

        return{values,error_msg,handle_create_application,reset_values}
    }
}
</script>

<style scoped>
    .add-applications{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        color: #fff;
    }
    .field{
        width: 100%;
        height: auto;
        margin-bottom: 15px;
    }
    .input{
        width: 80%;
    }
</style>